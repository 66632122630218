
.u-flex {
  display: flex;
}

.u-flex-ai-c {
  align-items: center;
}

.u-flex-ai-fe {
  align-items: flex-end;
}

.u-flex-jc-sb {
  justify-content: space-between;
}