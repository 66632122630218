
.mi-footer {
  position: relative;
  padding: 7rem 0 0;
  background-color: #040403;
  overflow: hidden;
  z-index: 1;

  @include respondDf(lg) {
    padding: 3rem 0 0;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 2.5%;
    display: inline-block;
    width: 67rem;
    height: 34rem;
    background-image: url('#{$path-image}/layout/bg-polygon-footer.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .mi-col--4 {
    @include respondDf(lg) {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
  }
}

.mi-footer__address p {
  text-align: right;
  font-family: $font-family-1;
  font-size: 1.6rem;
  line-height: 2.25;
  color: #797979;

  @include respondDf(lg) {
    text-align: center;
  }
}

.mi-footer__connection {
  @include respondDf(lg) {
    margin: 2rem 0 0;
  }
  @include respondDf(md) {
    margin: 2rem 0;
  }
}

.mi-footer__img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.mi-footer__phone a,
.mi-footer__mail a {
  display: flex;
  align-items: center;

  @include respondDf(lg) {
    justify-content: center;
  }

  span {
    font-family: $font-family-2;
    font-size: 2.4rem;
    line-height: 2;
    color: #797979;
    transition: .25s all;

    @include respondDf(md) {
      font-size: 1.6rem;
    }
  }

  img {
    margin-right: 2rem;
  }

  &:hover span {
    text-decoration: underline;
  }
}

.mi-footer__nav {
  position: relative;
  display: inline-block;
  padding: 2rem 0;
  background-color: #141414;

  &::before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 50vw;
    background-color: #141414;
  }

  &::after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9rem 15rem 0 0;
    border-color: #141414 transparent transparent transparent;

    @include respondDf(lg) {
      width: 50vw;
      height: 100%;
      border: none;
      background-color: #141414;
    }
  }
}

.mi-footer__nav ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mi-footer__nav ul li {
  display: inline-block;

  @include respondDf(md) {
    width: 100%;
  }

  &:not(:last-of-type) a::after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: .1rem;
    background-color: #040403;

    @include respondDf(md) {
      display: none;
    }
  }
}

.mi-footer__nav a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  padding: 0 6rem;
  font-weight: 400;

  @include respondDf(xlg) {
    padding: 0 3rem;
  }

  &:link,
  &:visited {
    color: #fff;
  }
  &:hover,
  &:active {
    color: $color-1;
  }
}

.mi-footer__copyright {
  margin: 0;
  text-align: center;
  font-size: 1.6rem;
  color: #797979;
}

.mi-footer__social {
  a {
    display: inline-block;
    width: 4rem;
    height: 4rem;

    &:hover {
      path {
        fill: $color-1;
      }
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    transition: .25s all;
  }
}

.mi-footer__bottom {
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}