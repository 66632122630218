
.mi-cd-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 0;
}

.mi-cd-pagination__pages {
  display: flex;
  justify-content: center;
  margin: 0 10rem;

  @include respondDf(md) {
    margin: 0;
  }
}

.mi-cd-pagination .mi-icon {
  flex: 0 0 4.5rem;
  max-width: 4.5rem;
  width: 4.5rem;
  height: 5.3rem;
  margin: 0 1rem;

  > img:first-of-type {;
    width: 4.5rem;
    height: 5.3rem;
  }

  span {
    font-size: 1.8rem;
    font-weight: 700;
  }
}

.mi-cd-pagination > a {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: .01em;

  span {
    position: relative;
    display: inline-block;

    @include respondDf(md) {
      display: none;
    }
  }

  span::before {
    content: '';
    position: absolute;
    bottom: -2rem;
    left: 0;
    width: 4rem;
    height: .2rem;
    background-color: $color-1;
    transition: .25s all;
  }

  img {
    margin: 0;
  }

  &:hover {
    span::before {
      width: 100%;
    }
  }
}

.mi-cd-pagination > a:first-of-type {
  margin-left: -5rem;

  @include respondDf(md) {
    margin-left: 0;
  }

  img {
    margin-right: 1.5rem;
  }
  span::before {
    left: auto;
    right: 0;
  }
}

.mi-cd-pagination > a:last-of-type {
  img {
    margin-left: 1.5rem;
  }
}
