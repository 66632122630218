
.mi-mosaic {
  display: flex;
  flex-wrap: wrap;
  border: .1rem solid #dfdfdf;
  background-color: #dfdfdf;
}

.mi-mosaic__item {
  flex: 0 0 33.3333%;
  width: 33.3333%;
  max-width: 33.3333%;
  min-height: 12rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border: .1rem solid #dfdfdf;
  background-color: #fff;

  @include respondDf(lg) {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }

  @include respondDf(lg) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }

  &--empty {
    cursor: none;
    pointer-events: none;

    @include respondDf(lg) {
      display: none;
    }
  }

  &--active {
    position: relative;
    pointer-events: none;
    cursor: none;

    &::after {
      content: '';
      position: absolute;
      top: -.2rem;
      right: -.2rem;
      bottom: -.2rem;
      left: -.2rem;
      border: .2rem solid $color-1;
      background-color: transparent;
    }
  }

  &:not(&--empty):hover {
    position: relative;
    box-shadow: 0 0 0 .1rem rgba(#dfdfdf, .8), 0 .6rem .9rem rgba(#dfdfdf, .9);
    z-index: 1;
  }
}

.mi-mosaic__pic {
  flex: 0 0 9.5rem;
  width: 9.5rem;
  max-width: 9.5rem;
  text-align: center;

  img {
    max-width: 100%;
  }
}

.mi-mosaic__title {
  display: inline-block;
  margin-left: 1.5rem;
  font-family: $font-family-2;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: .01em;
  user-select: none;

  .mi-mosaic__item--active & {
    text-decoration: underline;
  }
}
