
.mi-header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 5.6rem); // topbar
  min-height: 70rem;
  background-image: url('#{$path-image}/layout/header-main-bg.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @include respondDf(xlg) {
    min-height: 76rem;
    overflow: hidden;
  }

  @include respondDf(lg) {
    min-height: .1rem;
  }

  @include respondDf(md) {
    height: calc(100vh - 10rem); // topbar
    min-height: 72rem;
    justify-content: flex-end;
  }

  &::after {

    @include respondDf(lg) {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(black, .6);
    }
  }

  .mi-container {
    position: relative;
    top: 5%;

    @include respondDf(lg) {
      top: 0;
      z-index: 5;
    }

    @include respondDf(md) {
      margin: 0 0 2rem;
    }
  }

  .mi-col--8 {
    @include respondDf(lg) {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
  }
}

.mi-header__content {
  padding: 0 6rem;
  text-align: center;

  @include respondDf(lg) {
    padding: 0;
  }

  span {
    position: relative;
    display: inline-block;
    padding: 0 0 5rem;
    margin: 0 0 5rem;
    font-family: $font-family-2;
    font-size: 1.8rem;
    text-transform: uppercase;
    letter-spacing: .01em;
    color: #fff;

    @include respondDf(xlg) {
      padding: 0 0 2rem;
      margin: 0 0 2rem;
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 4.3rem;
      height: .2rem;
      transform: translateX(-50%);
      background-color: $color-1;
    }
  }

  p {
    margin: 0 0 5rem;
    padding: 0 8rem;
    line-height: 2.25;
    color: $color-4;

    @include respondDf(md) {
      padding: 0;
      color: #fff;
    }
  }
}

.mi-header__picture {
  position: absolute;
  right: 0;
  bottom: -35rem;
  width: 48vw;
  max-width: 94rem;

  @include respondDf(macbook) {
    bottom: -31rem;
  }
  @include respondDf(xlg) {
    bottom: -6rem;
  }

  @include respondDf(md) {
    width: 100%;
  }
}
