
.mi-btn {
  display: inline-block;
  padding: 0 6rem;
  line-height: 5.3rem;
  font-size: 1.8rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .01em;
  border: .2rem solid $color-1;

  &:link,
  &:visited {
    background-color: transparent;
    color: #fff;
  }

  &:hover,
  &:active {
    background-color: $color-1;
  }

  &--light {
    border-color: #fff;

    &:link,
    &:visited {
      background-color: #fff;
      color: $color-1;
    }

    &:hover,
    &:active {
      color: #fff;
      background-color: transparent;
    }
  }
}

