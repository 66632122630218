.mi-container {
  width: 100%;
  max-width: 124rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.mi-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
}

.mi-col {
  flex: 0 0 100%;
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 1px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  &--2 {
    flex: 0 0 16.6667%;
    width: 16.6667%;
    max-width: 16.6667%;
  }

  &--3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }

  &--4 {
    flex: 0 0 33.3333%;
    width: 33.3333%;
    max-width: 33.3333%;
  }

  &--5 {
    flex: 0 0 41.6667%;
    width: 41.6667%;
    max-width: 41.6667%;
  }

  &--6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }

  &--7 {
    flex: 0 0 58.3333%;
    width: 58.3333%;
    max-width: 58.3333%;
  }

  &--8 {
    flex: 0 0 66.6667%;
    width: 66.6667%;
    max-width: 66.6667%;
  }

  &--right-1 {
    margin-right: 8.3333%;
  }

  &--right-2 {
    margin-right: 16.6667%;
  }

  &--right-3 {
    margin-right: 25%;
  }

  &--right-4 {
    margin-right: 33.3333%;
  }

  &--left-2 {
    margin-left: 16.6667%;
  }

  &--left-3 {
    margin-left: 25%;
  }
}
