
.mi-benefit {
  display: flex;
  align-items: center;
  max-width: 52rem;

  @include respondDf(lg) {
    max-width: 100%;
    margin: 0 0 3rem;
  }

  &:not(:last-of-type) {
    margin: 0 0 3rem;
  }

  .mi-icon {
    margin-right: 4rem;

    @include respondDf(md) {
      margin-right: 2rem;
    }
  }
  p {
    margin: 0;
    line-height: 1.875;
    color: #fff;
  }
}
