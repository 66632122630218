
.mi-cd-box-container {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
}

.mi-cd-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 45rem;
  flex: 0 0 calc(33.3333% - 2rem);
  max-width: calc(33.3333% - 2rem);
  width: calc(33.3333% - 2rem);
  margin: 1rem;
  padding: 4.5rem;
  background-color: #fff;
  box-shadow: 0 .6rem 4rem 0 rgba(0, 0, 0, 0.08);
  transition: .25s;

  @include respondDf(lg) {
    flex: 0 0 calc(50% - 2rem);
    max-width: calc(50% - 2rem);
    width: calc(50% - 2rem);
  }

  @include respondDf(md) {
    flex: 0 0 calc(100% - 2rem);
    max-width: calc(100% - 2rem);
    width: calc(100% - 2rem);
  }

  &:hover {
    box-shadow: none;
  }

  .mi-icon {
    position: absolute;
    left: -1rem;
    top: 10rem;
    flex: 0 0 6rem;
    max-width: 6rem;
    width: 6rem;
    height: 7rem;

    > img:first-of-type {;
      width: 6rem;
      height: 7rem;
    }
  }
}

.mi-cd-box__pic {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
  width: 100%;
  max-width: 100%;
  margin: 2rem 0;

  img {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    font-family: 'object-fit:contain;';
  }
}

.mi-cd-box__title {
  width: 100%;
  max-width: 100%;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: .01em;
  color: $color-1;
}

.mi-cd-box__desc {
  width: 100%;
  max-width: 100%;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.25;
  letter-spacing: .01em;
  color: $color-6;
}
