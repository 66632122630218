
.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5rem 0;
  background-color: #f3f3f3;
  z-index: 1000;

  display: none;

  &--approve {
    display: none;
  }
}

.cookies .mi-col {
  display: flex;
  justify-content: space-between;

  @include respondDf(md) {
    display: block;
    text-align: center;
  }
}

.cookies p {
  max-width: 65rem;
  margin: 0;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.688;
}

.cookies a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18rem;
  height: 4.7rem;
  border: .1rem solid $color-1;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1;

  @include respondDf(md) {
    margin: 2rem auto 0;
  }

  &:link,
  &:visited {
    text-decoration: none;
    color: #fff;
    background-color: $color-1;
    transition: all .25s;
  }

  &:hover,
  &:active {
    color: $color-1;
    background-color: transparent;
  }
}