.mi-topbar {
  padding: 1.5rem 0;
  font-weight: 400;
  color: $color-2;
  background-color: $color-1;

  @include respondDf(md) {
    font-size: 1rem;
  }
}

.mi-topbar a {
  &:link,
  &:visited {
    color: $color-2;
  }
  &:hover span,
  &:active span {
    text-decoration: underline;
  }
}

.mi-topbar__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mi-topbar__text,
.mi-topbar__phone,
.mi-topbar__mail,
.mi-topbar__shop {
  display: flex;
  align-items: center;
  padding: 0 2rem;

  @include respondDf(md) {
    padding: 0 1rem;
  }

  @media only screen and (max-width: 374px) {
    padding: 0 .5rem;
  }

  a {
    display: flex;
    align-items: center;

    span {
      white-space: nowrap;
    }

    img {
      padding: 0 1rem 0 0;
    }
  }
}

.mi-topbar__text {
  @include respondDf(lg) {
    display: none;
  }
}

.mi-topbar__phone img {
  height: 2.6rem;
}
.mi-topbar__mail img {
  height: 1.7rem;
}
.mi-topbar__shop img {
  height: 1.8rem;
  opacity: .6;
}

.mi-topbar__shop span {

  @media only screen and (max-width: 374px) {
    display: none;
  }
}
