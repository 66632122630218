html {
  font-size: 62.5%; // 1rem = 10px
}

body {
  font-size: 1.6rem;
  font-family: $font-family-1;
  font-weight: 300;
}

input:focus,
button:focus,
textarea:focus {
  outline: none;
}

.mi-section {
  background-color: #fff;
  padding: 10rem 0;

  @include respondDf(md) {
    padding: 6rem 0;
  }

  h2 {
    text-align: center;
  }

  &--overflow {
    overflow: hidden;
  }

  &--bg-polygon {
    background-image: url('#{$path-image}/layout/bg-polygon.png');
    background-repeat: no-repeat;
    background-position: 0 535%;
  }

  &--bg-polygon-v2 {
    background-position: 0 -20%;

    @include respondDf(lg) {
      position: relative;
    }

    &::before {
      @include respondDf(lg) {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(white, .9);
      }
    }
  }

  &--bg-contact {
    position: relative;
    background-image: url('#{$path-image}/layout/bg-contact.png');
    background-repeat: no-repeat;
    background-position: -6% 0;

    @include respondDf(macbook) {
      background-position: -40% 0;
    }

    @include respondDf(ntb) {
      background-position: -55% 0;
    }

    @include respondDf(xlg) {
      .mi-col--5,
      .mi-col--4 {
        flex: 0 0 50%;
        max-width: 50%;
        width: 50%;
      }
      .mi-col--left-2 {
        margin-left: 0;
      }
      .mi-col--right-1 {
        margin-right: 0;
      }
    }

    @include respondDf(lg) {
      background-size: auto 100%;
      background-position: left top;

      .mi-col--5,
      .mi-col--4 {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
      }
    }

    &::before {
      @include respondDf(xlg) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(#fff,.7);
      }
    }

    h2,
    p {
      text-align: left;
    }

    p {
      color: $color-5;
    }
  }

  &--v2 {
    background-color: $color-1;

    p {
      color: $color-2;
    }

    h2 {
      text-align: left;
      color: #fff;
    }
  }

  &--category-intro,
  &--offer-intro,
  &--offer-d-intro {

    h1 {
      color: $color-5;
    }

    p {
      color: $color-6;
    }

    .mi-col--6,
    .mi-col--5 {
      @include respondDf(lg) {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
      }
    }
    .mi-col--right-1,
    .mi-col--right-3 {
      @include respondDf(lg) {
        margin-right: 0;
      }
    }
    .mi-col--left-3 {
      @include respondDf(lg) {
        margin-left: 0;
      }
    }
  }

  &--offer-intro,
  &--offer-d-intro {
    padding: 10rem 0 5rem;
    background-image: url('#{$path-image}/layout/bg-offer.png');
    background-repeat: no-repeat;
    background-position: 100% 50%;

    @include respondDf(md) {
      background-position: center bottom;
      background-size: cover;
    }
  }

  &--offer-d-intro {
    background-position: 100% -600%;
    background-image: url('#{$path-image}/layout/bg-offer-detail.png');

    @include respondDf(xlg) {
      background-position: center center;
    }

    & + .mi-section--bg-contact {
      background-color: $color-7;
    }

    & ~ .mi-section--category-section {
      background-color: #fff;
    }

    .mi-col--6:last-of-type {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  &--offer {
    overflow: hidden;
    background-image: url('#{$path-image}/layout/bg-offer.jpg');
    background-repeat: no-repeat;
    background-position: center bottom;

    .mi-col--6,
    .mi-col--5 {
      @include respondDf(md) {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
      }
    }

    .mi-col--right-3,
    .mi-col--right-1 {
      @include respondDf(md) {
        margin-right: 0;
      }
    }
    .mi-col--left-3 {
      @include respondDf(md) {
        margin-left: 0;
      }
    }
  }

  &--offer-know-how {

    p {
      color: $color-6;
    }

    .mi-col--8 {
      @include respondDf(md) {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
      }
    }

    .mi-col--left-2 {
      @include respondDf(md) {
        margin-left: 0;
      }
    }

    .mi-col--right-2 {
      @include respondDf(md) {
        margin-right: 0;
      }
    }

    h2 + p {
      text-align: center;
    }

    img {
      max-width: 100%;
      margin: 5rem auto 0;
    }
  }

  &--offer-bottom {
    .mi-row {
      align-items: center;
    }
    .mi-col--5,
    .mi-col--3 {
      @include respondDf(lg) {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
      }
    }
    .mi-col--right-4 {
      @include respondDf(lg) {
        margin-right: 0;
      }
    }
    h2 {
      margin: 0;

      @include respondDf(lg) {
        margin: 0 0 2rem;
      }
    }
    .mi-btn {
      padding: 0 2rem;
    }
  }

  &--product-intro {

    p {
      color: $color-6;
    }

    h1 {
      color: $color-5;
      margin: 0;
    }

    .mi-col--6,
    .mi-col--5 {
      @include respondDf(lg) {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
      }
    }
    .mi-col--right-1,
    .mi-col--right-3 {
      @include respondDf(lg) {
        margin-right: 0;
      }
    }
    .mi-col--left-3 {
      @include respondDf(lg) {
        margin-left: 0;
      }
    }
  }
  
  &--category-section,
  &--category-other {
    background-color: #f2f2f2;

    * {
      color: $color-6;
    }

    .mi-col--8 {
      @include respondDf(md) {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
      }
    }
    .mi-col--right-2 {
      @include respondDf(md) {
        margin-right: 0;
      }
    }
    .mi-col--left-2 {
      @include respondDf(md) {
        margin-left: 0;
      }
    }
  }

  &--category-other {
    background-color: #fff;
  }

  &--category-detail {
    padding-top: 3rem;
    background-color: #f2f2f2;
    background-position: 0 -240%;
  }

  &--product-detail {
    padding-top: 6rem;
    background-color: #f2f2f2;

    h2 {
      margin: 0 0 3rem;
      text-align: left;
    }

    h2 + img {
      position: absolute;
      right: 1.5rem;
      top: 1rem;

      @include respondDf(lg) {
        position: static;
        margin: 0 0 2rem;
      }
    }

    .id-product {
      color: #000;
      margin-right: 30px;

      b {
        font-family: $font-family-2;
        font-weight: 700;
      }
    }

    .mi-col--6 {

      @include respondDf(md) {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &--boxes {
    .mi-col--6 {
      @include respondDf(lg) {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &--benefits {
    .mi-col--5,
    .mi-col--6 {
      @include respondDf(lg) {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
      }
    }
    .mi-col--right-1 {
      @include respondDf(lg) {
        margin-right: 0;
      }
    }
  }

  &--contact-map {
    padding: 10rem 0 22rem;
    background-image: url('#{$path-image}/layout/bg-map.png'), url('#{$path-image}/layout/bg-map-polygon.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: 50% 120%, 95% 110%;

    @include respondDf(md) {
      position: relative;
      padding: 4rem 0 16rem;
      background-position: 65% 80%, 95% 110%;
    }

    &::before {
      @include respondDf(md) {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(#fff, .7);
      }
    }

    h2,
    p {
      text-align: left;
    }
    p {
      color: $color-6;
    }

    .mi-col--5 {
      @include respondDf(md) {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &--contact-connection {
    margin-top: -15rem;
    padding: 0;
    background-color: transparent;

    .mi-col--6 {
      @include respondDf(xlg) {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
      }
    }
    .mi-col--3 {
      @include respondDf(xlg) {
        flex: 0 0 50%;
        max-width: 50%;
        width: 50%;
      }

      @include respondDf(md) {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &--contact-addresses {
    p {
      color: $color-6;
    }

    .mi-col--6 {
      @include respondDf(md) {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &--who {
    background-image: url('#{$path-image}/layout/bg-who.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 50% auto;

    @include respondDf(lg) {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(white, .7);
      }
    }
  }

  &--who-stand {
    background-color: $color-7;
    background-image: url('#{$path-image}/layout/bg-polygon-who-stand.png');
    background-repeat: no-repeat;
    background-position: 100% -180%;

    @include respondDf(lg) {
      background-position: 100% -350%;

      .mi-col--6,
      .mi-col--5 {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
      }

      .mi-col--right-1 {
        margin-right: 0;
      }
    }

    @include respondDf(md) {
      background-position: 100% -150%;
    }

    p {
      color: $color-6;
    }

    h2,
    p {
      text-align: left;
    }

    img {
      max-width: 100%;
      margin: 7rem 0 0;

      @include respondDf(lg) {
        display: block;
        margin: 0 auto;
      }
    }
  }
}
