
.mi-contact__connection {
  display: flex;
  align-items: center;
  margin: 0 0 2rem;

  .mi-icon {
    margin-right: 4rem;

    @include respondDf(md) {
      margin-right: 1.5rem;
    }
  }

  span {
    font-family: $font-family-2;
    font-size: 2.4rem;
    font-weight: 700;
    color: $color-5;
    transition: .25s all;

    @include respondDf(md) {
      font-size: 1.6rem;
    }
  }

  &:hover span {
    color: $color-1;
  }
}

.mi-contact__connections {
  position: relative;
  padding: 6rem 0;
  box-shadow: 1.9068rem 3.7422rem 7rem 0 rgba(0, 0, 0, 0.25);
  background-color: rgb(242, 242, 242);

  @include respondDf(xlg) {
    padding: 3rem 1.5rem;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 50vw;
    box-shadow: 1.9068rem 3.7422rem 7rem 0 rgba(0, 0, 0, 0.25);
    background-color: rgb(242, 242, 242);

    @-moz-document url-prefix() {
      box-shadow: -1rem 3.7422rem 7rem 0 rgba(0, 0, 0, 0.25);
    }

    @include respondDf(xlg) {
      display: none;
    }
  }
  &::after {
    box-shadow: none;
    right: 90%;
  }

  .mi-icon>img:first-of-type {
    z-index: 1;
  }
}

.mi-contact__img-1,
.mi-contact__img-2 {
  width: 35rem;
  height: 26rem;
  box-shadow: 0 .6rem 4rem 0 rgba(0, 0, 0, 0.15);

  @include respondDf(md) {
    max-width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit:cover';
  }
}

.mi-contact__img-1 {
  position: relative;
  margin-left: -45%;
  margin-top: -10%;
  z-index: 10;

  @include respondDf(xlg) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
  }
}

.mi-contact__img-2 {
  margin-left: -45%;

  @include respondDf(xlg) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
  }
}

.mi-contact__address {
  display: flex;

  > * {
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
  }
}