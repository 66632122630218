
.mi-back {
  position: absolute;
  top: -1rem;
  left: 1.5rem;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: .01em;

  @include respondDf(md) {
    position: static;
    margin: 0 0 8rem;
  }

  span {
    position: relative;
    display: inline-block;
    color: $color-1;
  }

  span::before {
    content: '';
    position: absolute;
    bottom: -2rem;
    right: 0;
    width: 4rem;
    height: .2rem;
    background-color: $color-1;
    transition: .25s all;
  }

  img {
    margin: 0 1.5rem 0 0;
  }

  &:hover {
    span::before {
      width: 100%;
    }
  }
}
