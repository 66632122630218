
.mi-cd-header {
  margin-bottom: 4rem;

  > *:last-child {
    display: flex;
    justify-content: flex-end;
  }

  .mi-col--4 {
    @include respondDf(md) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }

    &:nth-of-type(2) {
      @include respondDf(md) {
        margin: 2rem 0;
      }
    }
  }
}

.mi-cd-info {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    width: 11.5rem;
    max-width: 100%;
  }
}

.mi-cd-selector label {
  display: block;
  margin: 0;
  font-family: $font-family-2;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: .01em;
  color: $color-5;
}


// select2
.select2-search {
  display: none;
}
.select2-container--open .select2-dropdown--below {
  width: 30rem !important;
  border: none;
  border-radius: 0;
  background-color: #cfcfcf;

  @include respondDf(md) {
    width: 25rem !important;
  }
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: $color-2;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $color-1;
}
.mi-cd-selector {
  width: 30rem;

  @include respondDf(md) {
    width: 25rem;
    margin: auto;
  }

  .select2-container {
    width: auto !important;

    @include respondDf(md) {
      width: 25rem !important;
    }
  }

  .select2-container--default .select2-selection--single {
    position: relative;
    display: flex;
    align-items: center;
    width: 30rem;
    height: 4.5rem;
    border: none;
    border-bottom: .1rem solid #cfcfcf;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: transparent;

    @include respondDf(md) {
      width: 25rem;
    }

    &:focus {
      outline: none;
    }
  }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    font-family: $font-family-2;
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: .01em;
    color: $color-5;
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    width: 30rem;
    padding-left: 0;
    padding-right: 3rem;

    @include respondDf(md) {
      width: 25rem;
    }
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 0;
    top: 0;
    height: 100%;
    width: 3rem;

    b {
      width: 2.1rem;
      height: 1.2rem;
      margin-top: -.4rem;
      border: none;
      background-image: url('#{$path-image}/icons/i-arrow.png');
      background-size: 2.1rem 1.2rem;
      background-position: right center;
      background-repeat: no-repeat;
    }
  }
}
