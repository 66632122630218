.mi-category__picture {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include IESelector() {
    display: block;
  }

  @include respondDf(lg) {
    justify-content: center;
  }

  img {
    max-width: 100%;
  }

  .mi-icon {
    position: absolute;
    right: -4rem;
    top: 7rem;
    flex: 0 0 10.4rem;
    max-width: 10.4rem;
    width: 10.4rem;
    height: 12.1rem;

    @include respondDf(ntb) {
      right: 1.5rem;
    }

    > img:first-of-type {
      width: 10.4rem;
      height: 12.1rem;
    }
  }
}

.mi-section--offer-intro .mi-category__picture,
.mi-section--offer-d-intro .mi-category__picture {
  position: relative;

  .mi-icon {
    right: auto;
    top: 50%;
    left: 14%;
    transform: translate(-57%, -50%);
  }
}

.mi-section--offer-d-intro .mi-category__picture {
  left: 3rem;

  @include respondDf(xlg) {
    left: 0;
  }
}
