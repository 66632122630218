
.mi-col--list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: .01em;
  color: $color-6;

  li {
    position: relative;
    margin: 0 0 2rem;
    padding-left: 5rem;

    &::before {
      content: '';
      position: absolute;
      top: .5rem;
      left: 0;
      width: 1.1rem;
      height: 1.2rem;
      background-image: url('#{$path-image}/icons/i-polygon.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 1.1rem 1.2rem;

      @include IESelector() {
        background-image: url('#{$path-image}/icons/i-polygon.png');
      }
    }
  }
}
