
.mi-form {

  @include respondDf(lg) {
    padding: 2rem;
    background-color: #fff;
  }

  input[type=submit] {
    margin: 3rem 0 0;
    padding: 0 6rem;
    border: .1rem solid $color-1;
    font-size: 1.8rem;
    line-height: 5rem;
    text-align: center;
    text-transform: uppercase;
    color: $color-5;
    background-color: transparent;
    transition: .25s all;
    cursor: pointer;

    @include respondDf(md) {
      display: block;
      margin: 3rem auto 0;
    }

    &:hover {
      color: #fff;
      background-color: $color-1;
    }
  }
}


.mi-form__group {
  position: relative;
  display: flex;
  align-items: center;
  height: 7.5rem;
  border-bottom: .1rem solid #cfcfcf;

  @include respondDf(md) {
    height: 6rem;
  }

  &:not(:last-of-type) {
    margin: 0 0 1rem;
  }

  &--error {
    border-bottom-color: $color-8;
  }

  input,
  input::placeholder {
    font-family: $font-family-2;
    font-size: 1.8rem;
    color: $color-5;

    @include respondDf(md) {
      font-size: 1.4rem;
    }
  }

  label,
  span {
    position: absolute;
    top: 0;
    font-size: 1.4rem;
    font-weight: 700;
    color: #323232;
    opacity: 0;
    transition: .25s all;

    @include respondDf(md) {
      font-size: 1.2rem;
    }
  }

  label {
    left: 0;

    @include respondDf(md) {
      top: 1rem;
    }
  }

  span {
    right: 0;
    color: $color-8;

    @include respondDf(md) {
      top: -.5rem;
    }
  }

  input {
    width: 100%;
    border: none;
    background-color: transparent;

    @include respondDf(md) {
      padding-top: 3.5rem;
    }

    &:focus {
      padding: 2rem 0 0;

      &::placeholder {
        color: transparent;
      }

      & + label {
        opacity: 1;
      }
    }
  }
  &--error input {
    &::placeholder {
      color: transparent;
    }
    & ~ span,
    & + label {
      opacity: 1;
    }
  }
}
