
.navbar-container {
  position: fixed;
  top: 5.6rem;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: transparent;
  transition: .25s all;

  &--subpage,
  &--bg {
    background-color: #000;

    & + * {
      margin-top: 12rem;

      @include respondDf(lg) {
        margin-top: 7.4rem;
      }
    }
  }

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #000;
  }

  .mi-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.navbar-logo {
  display: inline-block;
  width: 16rem;
  position: relative;
  top: 2.8rem;

  @include respondDf(lg) {
    width: 13rem;
    top: 1rem;
  }

  .navbar-container.sticky & {
    top: 0;
  }


  .navbar-container--subpage & {
    top: 0;
    width: 25rem;

    @include respondDf(md) {
      width: 20rem;
    }
  }

  .navbar-container--bg & {
    top: 0;
  }

  &__img {
    max-width: 100%;

    @include respondDf(lg) {
      padding: 1rem 0;
    }
  }
}

.navbar {
  position: relative;

  @include respondDf(lg) {
    position: fixed;
    top: 0;
    left: 100%;
    bottom: 0;
    right: 0;
    min-height: 100vh;
    box-shadow: 0 -1rem 1rem rgba(0,0,0,.5);
    background-color: $color-1;
    z-index: 2000;
    transition: .25s left;

    #navbarCheckbox:checked ~ & {
      left: 20%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 6rem;
    display: block;
    height: .1rem;
    width: 100vw;
    background-color: rgba(#fff, .1);
    z-index: 20;
  }

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    @include respondDf(lg) {
      display: block;
      height: 100%;
      padding-top: 6rem;
      overflow: auto;
    }
  }

  a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12rem;
    padding: 0 3.3rem;
    text-transform: uppercase;
    font-family: $font-family-2;

    @include respondDf(lg) {
      justify-content: flex-start;
      height: 6rem;
      border-top: .1rem solid $color-2;
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -.1rem;
      display: inline-block;
      height: .2rem;
      width: 0;
      transform: translate(-50%,0);
      background-color: $color-1;
      transition: .25s right;

      @include respondDf(lg) {
        display: none;
      }
    }

    &:link,
    &:visited {
      text-decoration: none;
      font-size: 1.5rem;
      font-weight: 500;
      color: #a1a1a1;

      @include respondDf(lg) {
        color: $color-2;
      }
    }

    &.active {
      color: #fff;

      &::after {
        width: 2.7rem;

        @include respondDf(lg) {
          width: 1.6rem;
        }
      }
    }
    &:hover,
    &:active {
      color: $color-3;

      @include respondDf(lg) {
        color: $color-2;
      }
    }
  }
}

.navbar ul li:first-of-type a {
  @include respondDf(lg) {
    border-top: none;
  }
}

.navbar__dropdown {
  cursor: pointer;

  @include respondDf(lg) {
    position: relative;
  }

  > span {
    display: none;

    @include respondDf(lg) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 30%;
      height: 6rem;
      z-index: 2000;
    }
  }

  > a {
    position: relative;

    &.active:link,
    &.active:visited {
      @include respondDf(lg) {
        color: $color-2;
      }
    }

    &::after {
      width: 2.4rem;
      height: 2.4rem;
      transform: translate(-50%,50%);
      background-color: transparent;
      background-image: url('#{$path-image}/icons/i-polygon.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 2rem 2.4rem;
      z-index: 50;
      opacity: 0;

      @include IESelector() {
        background-image: url('#{$path-image}/icons/i-polygon.png');
      }

      @include respondDf(lg) {
        position: absolute;
        top: 1.8rem;
        right: 3rem;
        left: initial;
        width: 1.6rem;
        height: 1.6rem;
        border: solid black;
        border-width: 0 .3rem .3rem 0;
        border-color: $color-2;
        display: inline-block;
        padding: .3rem;
        transform: rotate(45deg);
        background-image: none;
        opacity: 1;
      }
    }

    & + ul {
      @include respondDf(lg) {
        display: none !important;
      }
    }

    &.active + ul {

      @include respondDf(lg) {
        display: flex !important;
      }
    }
  }

  > ul {
    position: absolute;
    top: 100%;
    left: 12rem;
    right: 0;
    display: none;
    flex-wrap: wrap;
    max-height: 0;
    padding: 1.5rem 0;
    background-color: #000;
    z-index: 10;
    transition: all .4s;

    &::before {
      content: '';
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      display: block;
      width: 20rem;
      background-image: linear-gradient(to left bottom, #000 0%, #000 50%, transparent 50%);

      @include respondDf(lg) {
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;
      display: block;
      width: 50vw;
      background-color: #000;

      @include respondDf(lg) {
        display: none;
      }
    }

    @include respondDf(lg) {
      position: static;
      max-height: initial;
      padding: 0 0 0 4rem;
      opacity: 1;
      background-color: transparent;
    }

    .navbar-container.sticky & {

    }
  }

  > ul li {
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;

    @include respondDf(lg) {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
  }

  > ul li a {
    display: flex;
    justify-content: flex-start;
    height: auto;
    padding: 1.5rem 3rem;

    @include respondDf(lg) {
      height: 4rem;
      padding: 0 3rem;
      text-transform: none;
      border-top: none;

      &:link,
      &:visited {
        color: $color-2;
      }
    }

    @include respondDf(md) {
      padding: 0;
    }

    img {
      margin-right: 2rem;

      @include respondDf(lg) {
        display: none;
      }
    }
  }

  > ul li:last-of-type,
  > ul li:last-of-type a {
    border-bottom-left-radius: .4rem;
    border-bottom-right-radius: .4rem;
    overflow: hidden;
  }

  > ul li:last-of-type {
    margin: 0 0 2rem;
  }

  &:hover {

    > a::after {
      opacity: 1;
    }
    > ul {
      max-height: 100rem;
      display: flex;
    }
  }
}

#navbarCheckbox {
  display: none;
}

#navbarCheckbox + label {
  display: none;

  @include respondDf(lg) {
    display: block;
    width: 4rem;
    height: 4rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    cursor: pointer;
  }
}

#navbarCheckbox:checked + label {

  @include respondDf(lg) {
    position: fixed;
    right: 2rem;
    top: 1rem;
    background-image: url("data:image/svg+xml,%3Csvg height='6.82666in' style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' viewBox='0 0 6.82666 6.82666' width='6.82666in' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E .fil1 %7Bfill:none%7D .fil0 %7Bfill:%23b3c2ef;fill-rule:nonzero%7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_x0020_1'%3E%3Cpath class='fil0' d='M5.91083 1.2175c0.0833031,-0.0833031 0.0833031,-0.218366 0,-0.301669 -0.0833031,-0.0833031 -0.218366,-0.0833031 -0.301669,0l-4.69334 4.69333c-0.0833031,0.0833031 -0.0833031,0.218366 0,0.301669 0.0833031,0.0833031 0.218366,0.0833031 0.301669,0l4.69334 -4.69333z'/%3E%3Cpath class='fil0' d='M1.2175 0.915827c-0.0833031,-0.0833031 -0.218366,-0.0833031 -0.301669,0 -0.0833031,0.0833031 -0.0833031,0.218366 0,0.301669l4.69334 4.69333c0.0833031,0.0833031 0.218366,0.0833031 0.301669,0 0.0833031,-0.0833031 0.0833031,-0.218366 0,-0.301669l-4.69334 -4.69333z'/%3E%3C/g%3E%3Crect class='fil1' height='6.82666' width='6.82666'/%3E%3C/svg%3E%0A");
    background-size: 4rem 4rem;
    z-index: 2100;
  }
}

#navbarSearching {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  cursor: pointer;

  @include respondDf(lg) {
    justify-content: flex-start;
    height: 6rem;
    padding: 0 3.3rem;
  }

  img {
    width: 2.2rem;
    height: 2.1rem;
  }
}

.navbar__lang {
  position: relative;
  padding: 0 3.3rem;

  @include respondDf(lg) {
    height: 6rem;
    border-top: .1rem solid $color-1;
  }

  &:hover a {
    display: block;
  }
}

.navbar__lang__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-1.3rem);
  padding: .4rem;
  border: .2rem solid $color-1;
  border-radius: 2rem;

  @include respondDf(lg) {
    left: 5rem;
    border-color: $color-2;
  }

  a:not(:first-of-type) {
    margin-top: .4rem;
  }

  a:not(.active) {
    opacity: .5;
    transition: .25s all;

    &:hover {
      opacity: 1;
    }
  }
}

.navbar__lang a {
  display: none;
  height: 1.6rem;
  width: 1.6rem;
  padding: 0;

  @include respondDf(lg) {
    border-top: none;
  }

  &::after {
    display: none;
  }

  &.active {
    display: block;
  }

  img {
    width: 1.6rem;
    height: 1.6rem;
  }
}