
// df - desktop first
$grid-breakpoints-df: (
  macbook: 1440px,
  ntb: 1366px,
  xlg: 1199px,
  lg: 991px,
  md: 767px,
  sm: 575px
);

// colors
$color-1: #3856b4;
$color-2: #b3c2ef;
$color-3: #2e57d9;
$color-4: #a1a1a1;
$color-5: #323232;
$color-6: #474747;
$color-7: #f2f2f2;
$color-8: #f83232;

// paths
$path-image: '../img';

// font family
$font-family-1: 'Open Sans', sans-serif;
$font-family-2: 'Montserrat', sans-serif;
