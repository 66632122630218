
.mi-offer {
  margin-top: 8rem;

  .mi-col--5 {
    @include respondDf(lg) {
      flex: 0 0 50%;
      width: 50%;
      max-width: 50%;
    }
    @include respondDf(md) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }

  .mi-col--right-1 {
    @include respondDf(lg) {
      margin-right: 0;
    }
  }

  h2 {
    text-align: left;
  }
}

.mi-offer-box {
  margin: 2rem 0 0;
  padding: 3rem 4rem;
  box-shadow: 0 .6rem 4rem 0 rgba(0, 0, 0, 0.08);
  background-image: url('#{$path-image}/layout/bg-apple.jpg');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-color: #fff;

  @include respondDf(md) {
    position: relative;
    z-index: 1;
  }

  &::before {
    @include respondDf(md) {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(white, .7);
      z-index: -1;
    }
  }

  h3 {
    margin: 0 0 2rem;
  }

  p {
    line-height: 1.875;
    margin: 0;
    padding-left: 15rem;

    @include respondDf(md) {
      padding-left: 0;
    }
  }

  &--erp {
    background-image: url('#{$path-image}/layout/bg-erp.jpg');
  }
}

.mi-offer-num {
  position: relative;
  margin: 12rem 0 0;
  padding: 3rem;
  color: #fff;
  background-color: $color-1;

  @include respondDf(md) {
    margin: 6rem 0 0;
    padding: 1.5rem;
  }

  &::after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 50vw;
    background-color: $color-1;

    @include respondDf(md) {
      display: none;
    }
  }

  .mi-icon {
    flex: 0 0 4.5rem;
    max-width: 4.5rem;
    width: 4.5rem;
    height: 5.3rem;
    margin-right: 2.5rem;

    @include respondDf(md) {
      margin-right: 1.5rem;
    }

    > img:first-of-type {
      width: 4.5rem;
      height: 5.3rem;
    }

    span {
      font-family: $font-family-2;
      font-size: 1.8rem;
      font-weight: 700;
      letter-spacing: .01em;
      color: $color-1;
    }
  }
}

.mi-offer-num__item {
  display: flex;

  &:not(:last-of-type) {
    margin: 0 0 2.5rem;
  }
}

.mi-offer-num__text {

  h3 {
    margin: 0 0 1rem;
    font-family: $font-family-2;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: .01em;
    color: #fff;
  }

  p {
    margin: 0;
    font-family: $font-family-1;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: $color-2;
  }
}
