.mi-breadcrumbs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 5rem;

  @include respondDf(md) {
    flex-wrap: wrap;
    margin: 0 0 3rem;
  }

  > * {
    position: relative;
    display: inline-block;
    padding: 0 4rem;

    @include respondDf(md) {
      padding: 0 2rem;
      margin: 0 0 2rem;
      white-space: nowrap;
    }

    &:first-child {
      @include respondDf(md) {
        padding-left: 0;
      }
    }
    &:last-child {
      @include respondDf(md) {
        padding-right: 0;
      }
    }
  }

  > *:not(:last-child)::after {
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    width: 1.2rem;
    height: 1.3rem;
    transform: translate(-50%, -50%);
    background-image: url('#{$path-image}/icons/i-arrow-sharp.svg');
    background-size: 1.2rem 1.3rem;
    background-position: center center;
    background-repeat: no-repeat;

    @include IESelector() {
      background-image: url('#{$path-image}/icons/i-arrow-sharp.png');
    }
  }
}

.mi-breadcrumbs a:link,
.mi-breadcrumbs a:visited,
.mi-breadcrumbs span {
  font-size: 1.4rem;
  font-weight: 400;
  color: #8a8a8a;
}

.mi-breadcrumbs a:link,
.mi-breadcrumbs a:visited {
  text-decoration: underline;
}

.mi-breadcrumbs a:hover,
.mi-breadcrumbs a:active {
  text-decoration: none;
  color: $color-5;
}
