
h1 {
  margin: 0 0 5rem;
  font-family: $font-family-2;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: .01em;
  color: #fff;

  @include respondDf(xlg) {
    margin: 0 0 2rem;
  }

  @include respondDf(md) {
    font-size: 3.4rem;
  }
}

h2 {
  margin: 0 0 5rem;
  font-family: $font-family-2;
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 1.333;
  letter-spacing: .01em;
  color: $color-5;

  @include respondDf(xlg) {
    margin: 0 0 2rem;
  }

  @include respondDf(md) {
    font-size: 3rem;
  }
}

h3 {
  margin: 0 0 3rem;
  font-family: $font-family-2;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 1.5;
  letter-spacing: .01em;
  color: $color-5;

  @include respondDf(xlg) {
    margin: 0 0 2rem;
  }

  @include respondDf(md) {
    font-size: 1.8rem;
  }
}

p {
  margin: 0 0 3rem;
  font-family: $font-family-1;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.25;
  color: $color-1;

  @include respondDf(xlg) {
    margin: 0 0 2rem;
  }
}

a {
  &:link,
  &:visited {
    text-decoration: none;
    color: $color-1;
    transition: .25s all;
  }
  &:hover,
  &:active {

  }
}