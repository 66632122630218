
.mi-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 6.4rem;
  max-width: 6.4rem;
  width: 6.4rem;
  height: 7.5rem;

  > img:first-of-type {
    position: absolute;
    top: 0;
    left: 0;
    width: 6.4rem;
    height: 7.5rem;
  }

  > img:last-of-type {
    position: relative;
    z-index: 1;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: $font-family-2;
    font-size: 1.1rem;
    z-index: 2;
    color: #fff;
    user-select: none;
  }

  &--v2 {
    flex: 0 0 4.5rem;
    max-width: 4.5rem;
    width: 4.5rem;
    height: 5.3rem;

    > img:first-of-type {
      width: 4.5rem;
      height: 5.3rem;
    }
  }

  &--wifi-cycle {
    > img:last-of-type {
      width: 4.5rem;
      height: 3.5rem;
    }
  }
}

