
.mi-download {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @include respondDf(md) {
    flex-wrap: wrap;
  }
}

.mi-download__item {
  flex: 0 0 10rem;
  max-width: 10rem;
  width: 10rem;
  margin: 0 2.5rem;
  text-align: center;

  span:last-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 4.2rem;
    margin: 2rem 0 0;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2222;
    letter-spacing: .01em;
    color: $color-1;
  }

  .mi-icon {
    flex: 0 0 10rem;
    max-width: 10rem;
    width: 10rem;
    height: 12rem;

    > img:first-of-type {
      width: 10rem;
      height: 12rem;
    }
  }
}
