
.mi-box {
  max-width: 48rem;

  @include respondDf(lg) {
    max-width: 100%;
    margin: 0 0 8rem;
  }

  &--v2 {
    position: relative;
    max-width: 100%;
    padding: 10rem 0 10rem 10rem;
    background-color: $color-7;
    box-shadow: -12rem -4rem 40rem -12rem gray;

    @include respondDf(md) {
      padding: 2rem 1.5rem 4rem;
    }

    &::after {
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;
      width: 50vw;
      background-color: $color-7;

      @include respondDf(md) {
        display: none;
      }
    }
  }

  &--3 {
    margin-top: -10rem;

    @include respondDf(xlg) {
      margin-top: -17rem;
    }

    @include respondDf(lg) {
      margin-top: 0;
      margin-bottom: 4rem;
    }
  }
}

.mi-box h3 {
  display: flex;
  align-items: center;
}

.mi-box h3 span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 4.5rem;
  width: 4.5rem;
  height: 5.3rem;
  margin-right: 3rem;
  font-size: 1.8rem;
  line-height: 1;
  color: #fff;
  background-color: transparent;
  background-image: url('#{$path-image}/icons/i-polygon.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 4.5rem 5.3rem;

  @include IESelector() {
    background-image: url('#{$path-image}/icons/i-polygon.png');
  }

  @include respondDf(md) {
    margin-right: 1.5rem;
    font-size: 1.6rem;
  }
}

.mi-box p {
  margin: 0 0 4rem;
  padding: 0 0 0 2rem;
  color: $color-5;
}

.mi-box a {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 0 2rem;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: .01em;

  span {
    position: relative;
    display: inline-block;
  }

  span::before {
    content: '';
    position: absolute;
    bottom: -2rem;
    left: 0;
    width: 4rem;
    height: .2rem;
    background-color: $color-1;
    transition: .25s all;
  }

  img {
    margin: 0 0 0 1.5rem;
  }

  &:hover {
    span::before {
      width: 100%;
    }
  }
}
