
.mi-lightslider-product {
  position: relative;
  margin-right: 4rem;

  @include respondDf(lg) {
    margin-right: 1.5rem;
  }

  @include respondDf(md) {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .lSSlideOuter .lightSlider{
    height: 43rem !important;
  }

  .lSPager li,
  .lSPager li.active,
  .lSSlideOuter .lSPager.lSGallery li.active,
  .lSSlideOuter .lSPager.lSGallery li:hover {
    border-radius: 0;
    background-color: #fff;
  }

  .lSSlideOuter .lSPager.lSGallery li {
    height: 10rem;
    overflow: visible;
    box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.08);

    &:hover {
      box-shadow: none;
    }
  }

  .lSSlideOuter .lSPager.lSGallery {
    margin-top: .7rem !important;
    overflow: visible;
  }

  .lSPager img {
    height: 100% !important;
    margin: 0;
    object-fit: contain;
    font-family: 'object-fit: contain;';

    @media only screen and (max-width: 1370px) {
      width: 100% !important;
    }
  }

  .lslide,
  .lightSlider li {
    height: 100%;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 .6rem 4rem 0 rgba(0, 0, 0, 0.08);
  }
  .lightSlider li img,
  .lslide img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    font-family: 'object-fit: contain;';
  }
}

.mi-lightslider-product__next {
  position: absolute;
  bottom: 3rem;
  right: 0;
  transform: translateX(50%);
  cursor: pointer;

  @include respondDf(md) {
    right: 2rem;
  }

  .mi-icon {
    flex: 0 0 3.8rem;
    max-width: 3.8rem;
    width: 3.8rem;
    height: 4.4rem;

    > img:first-of-type {
      width: 3.8rem;
      height: 4.4rem;
    }
  }
}
